//
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// Grayscale
$white:                 #fff !default;
$gray-100:              #f7f7fc !default;
$gray-200:              #f3f3f9 !default;
$gray-300:              #e9e9f2 !default;
$gray-400:              #dfdfeb !default;
$gray-500:              #9e9fb4 !default;
$gray-600:              #737491 !default;
$gray-700:            	#5a5b75 !default;
$gray-800:              #4a4b65 !default;
$gray-900:              #37384e !default;
$black:                 #000 !default;

// Brand colors
$primary:               #9430a1 !default;
$secondary:             #98ca37 !default;
$info:		              #827684 !default;
$success:               #98ca37 !default;
$warning:               #3076a1 !default;
$danger:                #ca374f !default;
$light:                 white !default;
$dark:                  $gray-900 !default;

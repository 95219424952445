// 
// user.scss
// Use this to write your custom SCSS
//
blockquote {
  // background: #ead6ec;
  background-color: #ead6ec;
  border-radius: 5px;
  padding: 15px;
}

blockquote p {
  margin: 0
}